<template>
  <div class="page-container">
    <div class="card-container">
      <div class="header">
        <img :src="logoIcon" width="98" alt="" class="logo" />
        <div class="title">{{ translateTitle("登录") }}</div>
      </div>
      <div class="form">
        <el-form
          ref="formRef"
          :rules="formRules"
          :model="formData"
          @submit.native.prevent
        >
          <el-form-item
            prop="account"
            :label="translateTitle('邮箱 / 手机 / 用户名')"
            v-if="isPhone"
            class="mobile-form-item"
          >
            <el-dropdown trigger="click" placement="bottom-start">
              <div class="area">
                <span>{{ mobileAreaCode }}</span>
                <ArrowUpIcon />
              </div>
              <el-dropdown-menu slot="dropdown">
                <div class="search-input-wrapper">
                  <el-input v-model="mobilSearchValue" class="search-input">
                    <template #prefix>
                      <img width="15" :src="searchIcon" alt="" />
                    </template>
                  </el-input>
                </div>
                <div class="mobile-list">
                  <el-dropdown-item
                    v-for="item in areaCodeList"
                    :key="item.ssc_id"
                  >
                    <div class="mobile-item" @click="onAreaCodeItemClick(item)">
                      <span v-if="item[language]">{{ item[language] }} </span>
                      <span>+{{ item.phone_code }}</span>
                    </div>
                  </el-dropdown-item>
                </div>
              </el-dropdown-menu>
            </el-dropdown>

            <el-input
              v-model="formData.account"
              :placeholder="translateTitle('输入用户名/邮箱/手机号')"
            >
              <template #suffix>
                <ClearIcon
                  v-if="!!formData.account"
                  @click="formData.account = ''"
                />
              </template>
            </el-input>
          </el-form-item>
          <el-form-item
            v-else
            prop="account"
            :label="translateTitle('邮箱 / 手机 / 用户名')"
          >
            <el-input
              v-model="formData.account"
              :placeholder="translateTitle('输入用户名/邮箱/手机号')"
            >
              <template #suffix>
                <ClearIcon
                  v-if="!!formData.account"
                  @click="formData.account = ''"
                />
              </template>
            </el-input>
          </el-form-item>

          <!-- <div @click="showInviteCode = !showInviteCode">
            <el-form-item>
              <template #label>
                <div class="invite-code">
                  <span>{{ translateTitle("邀请码（选填）") }}</span>
                  <ArrowUpIcon
                    class="icon"
                    :class="{ down: !showInviteCode }"
                  />
                </div>
              </template>
              <div @click.stop>
                <el-input
                  v-model="inviteCode"
                  :placeholder="translateTitle('请输入邀请码')"
                  v-if="showInviteCode"
                >
                  <template #suffix>
                    <ClearIcon v-if="!!inviteCode" />
                  </template>
                </el-input>
              </div>
            </el-form-item>
          </div> -->
        </el-form>

        <el-button class="next-btn" type="primary" @click="onNext">{{
          translateTitle("下一步")
        }}</el-button>
        <div class="other-btn" @click="$router.push('/register')">
          {{ translateTitle("创建一个账号") }}
        </div>
      </div>
    </div>
    <Geetest :isGeet="isShowGeet" :isRegister="false" @geetParam="success" />
  </div>
</template>

<script>
import logoIcon from "@/assets/img/users/logo.png";
import ClearIcon from "@/components/icons/ClearIcon.vue";
import ArrowUpIcon from "@/components/icons/ArrowUpIcon.vue";
import Geetest from "@/components/Geetest.vue";
import { UCenterRequest } from "@/model/UCenterService";
import { apiGetStaticTableInfo } from "@/model/settings";
import searchIcon from "@/assets/img/users/search.png";
export default {
  components: {
    ClearIcon,
    ArrowUpIcon,
    Geetest,
  },
  data() {
    return {
      logoIcon,
      searchIcon,
      showInviteCode: true,
      inviteCode: "",
      isShowGeet: false,
      formRules: {
        account: [
          {
            required: true,
            message: this.translateTitle("邮箱/手机号不能为空"),
            trigger: "blur",
          },
        ],
      },
      formData: {
        account: "",
      },
      isInputPassword: false,
      mobileAreaCode: "+86",
      staticSmsCountry: {},
      mobilSearchValue: "",
    };
  },
  computed: {
    language() {
      return this.$store.state.user.language;
    },
    isPhone() {
      if (!this.formData.account) return false;
      const reg = /^[0-9]*[1-9][0-9]*$/;
      const text = this.formData.account.substring(0, 3);
      if (this.formData.account.includes("@")) return false;
      return !!(
        text.length >= 3 &&
        reg.test(text) &&
        reg.test(this.formData.account)
      );
    },
    areaCodeList() {
      if (this.mobilSearchValue == "") {
        return this.staticSmsCountry;
      }
      let tempList = {};
      for (const key in this.staticSmsCountry) {
        const temp = this.staticSmsCountry[key];
        if (
          temp[this.language] &&
          temp.phone_code &&
          [
            temp[this.language].toLowerCase(),
            temp.phone_code.toString().toLowerCase(),
          ]
            .join("")
            .includes(this.mobilSearchValue)
        ) {
          tempList[key] = this.staticSmsCountry[key];
        }
      }
      console.log("tempList", tempList);
      return tempList;
    },
  },
  created() {
    const params = JSON.parse(JSON.stringify(this.$route.params));
    delete params["lang"];
    this.isInputPassword =
      Object.keys(params).length > 0 && this.$route.query.tokenLogin;
    console.log("route", this.$route);
    this.getStaticSmsCountry();
  },
  methods: {
    async getStaticSmsCountry() {
      const { status, data } = await apiGetStaticTableInfo(2);
      if (status == 200) {
        this.staticSmsCountry = JSON.parse(JSON.stringify(data));
        console.log("staticSmsCountry", this.staticSmsCountry);
      }
    },
    async success(params) {
      const res = await UCenterRequest.checkLoginName({
        login_name: this.formData.account,
        ...params,
      });
      if (res.status !== 200) return;
      this.isInputPassword = true;
      const area =
        this.mobileAreaCode[0] === "+"
          ? this.mobileAreaCode.substring(1)
          : this.mobileAreaCode;
      this.$router.push({
        name: "LoginInputPassword",
        query: {
          tokenLogin: res.data.token_login,
          account: this.formData.account,
          area,
        },
        params: {
          ...this.$route.params,
          ...res.data.login_info,
          token: res.data.token,
        },
      });
    },
    onNext() {
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          this.isShowGeet = !this.isShowGeet;
        }
      });
    },
    onAreaCodeItemClick(item) {
      this.mobileAreaCode = "+" + item.phone_code;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./styles/common.scss";

.next-btn {
  margin-top: 120px !important;
}
.mobile-list {
  width: 335px;
  height: 258px;
  overflow: auto;

  .mobile-item {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    color: var(--7-c_t01, #0d0e0e);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 100% */
  }
}
</style>
